<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
    @keydown.esc="goToProductsPage"
  >
    <ProductForm
      :product="newProduct"
      :errors="productValidationErrors"
      :disabled="$store.getters.loading['post:api/products']"
      @clear:errors="clearProductValidationErrors"
      @cancel="goToProductsPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ProductForm from '@/components/ProductForm';

export default {
  name: 'ProductsTable',

  components: { ProductForm },

  computed: mapState('products', ['newProduct', 'productValidationErrors']),

  created() {
    this.RESET_PRODUCT_VALIDATION_ERRORS();
  },

  methods: {
    ...mapActions('products', ['storeProduct', 'clearProductValidationErrors']),
    ...mapMutations('products', ['RESET_PRODUCT_VALIDATION_ERRORS']),

    onSave(product) {
      this.storeProduct(product).then(() => {
        this.goToProductsPage();
      });
    },

    goToProductsPage() {
      this.$router.push({ name: 'products', query: this.productFilterParams });
    },
  },
};
</script>

<style scoped></style>
